// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Bangers');
@import url('https://fonts.googleapis.com/css?family=Raleway');


$primary: #edcd46; /* MAIN COLOR */
$secondary: #535f74; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body {
	font-size: 1.5em;
	font-family: 'Raleway', sans-serif;

}
.logo {

}
nav {
	z-index: 1000;
	background: $primary;
}
.navbar-default {
	// background: $primary
}
.logo {
	@media(max-width:767px){
		max-height: 70px;
	}
}
.top-pad {
	font-size: 1.2em;
	h1, h2, h3, p, span, a {
		font-size: 1.3em;
		font-family: serif;
	}
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;
		color: $blk;


		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $secondary;
	    	color: lighten($secondary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
// start sections
section {
	padding: 50px 0;
	position: relative;
	h1,h2,h3, h4, h5, h6 {
		font-family: 'Bangers', cursive;

	}
}
.section-a{
	padding:350px 0;
	background: url('../img/bg1.jpg') no-repeat top center/cover fixed;
	@media(max-width:1024px){
		background: url('../img/bg1.jpg') no-repeat top center/cover scroll;
			padding:150px 0;
	}
	@media(max-width:1024px){
		background: url('../img/bg1.jpg') no-repeat top center/cover scroll;
			padding:70px 0;
	}
	h1 {
		@media(max-width:767px){
			font-size: 1.5em;
		}
	}

}
.section-b{
	padding: 50px 0;
	background: url('../img/bg3.gif') no-repeat center/cover;
	@media(max-width:767px){
		padding: 10px 0;
	}
}
.section-c{
	padding: 250px 0;
	background: url('../img/bg2.jpg') no-repeat center/cover fixed;
	@media(max-width:1024px){
		background: url('../img/bg2.jpg') no-repeat center/cover scroll;
			padding: 150px 0;
	}
	@media(max-width:767px){
		padding: 50px 0;
	}
	p {
		@media(max-width:767px){
		font-size: 1em;
		}
	}
}
.section-d{
	padding: 150px 0;
	background: $primary;
	color: darken($secondary, 25%);
	@media(max-width:767px){
		padding: 50px 0;
	}
	h1 {
	text-shadow: 0px 0px 12px darken($secondary, 25%);
		@media(max-width: 767px){
				text-align: center;
			}
	}
	p {
		text-shadow: 0px 0px 1px darken($wht, 25%);
		color: darken($primary, 45%);
		@media (max-width: 767px){
			font-size: 1em;
			text-align: center;
		}
	}

}
// ends sections
h1 {
	font-size:  2.5em;
	line-height: 1.5em;
	color: $primary;
	text-shadow: 3px 3px 8px darken($secondary, 25%);
	text-transform: uppercase;
}
p {
	font-size: 1.5em;
	color: $wht;
}
/** END LOGIN FORM **/
.cta {
	background: darken($primary, 15%);
	color:$wht;
	padding: 10px 25px;
	transition: all 0.7s ease 0s;
	font-size: 1.2em;
	margin: 5px;

	&:hover{
		background: darken($secondary, 15%);
		color:$wht;
	}
}
footer {
	padding: 20px 0px 20px;
	background: $secondary;
	color: $footerLinks;
	font-size: .7em;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}
